import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import Print from "../Print";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import { useNavigate } from "react-router-dom";
import dragula from "dragula";
import "dragula/dist/dragula.css";

const OrderStatus = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [printMode, setPrintMode] = useState(false);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [processingOrder, setProcessingOrder] = useState([]);
  const [readyOrder, setReadyOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});

  const pendingRef = useRef(null);
  const processingRef = useRef(null);
  const readyRef = useRef(null);

  const [status, setStatus] = useState("");
  const [selectIron, setSelectIron] = useState(false);
  const [selectedIron, setSelectedIron] = useState({});
  const [ironMan, setIronMan] = useState([]);
  const pendingOrderRef = useRef([]);
  const processingOrderRef = useRef([]);
  const readyOrderRef = useRef([]);

  const getOrders = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("status", "Pending");

    const response = await axiosInstance.get(`/orders?${searchParams}`);

    if (response.status === "success") {
      setPendingOrder(response.data);
    }

    const searchParams2 = new URLSearchParams();
    searchParams2.append("status", "Processing");

    const response2 = await axiosInstance.get(`/orders?${searchParams2}`);

    if (response2.status === "success") {
      setProcessingOrder(response2.data);
    }

    const searchParams3 = new URLSearchParams();
    searchParams3.append("status", "Ready to Deliver");

    const response3 = await axiosInstance.get(`/orders?${searchParams3}`);

    if (response3.status === "success") {
      setReadyOrder(response3.data);
    }

    $("#load").hide();
  };

  const getIronman = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const ironman = await axiosInstance.get(`/ironmans`);

        if (ironman.status === "success") {
          setIronMan(ironman.data);
          resolve(ironman.data);
        } else {
          reject(new Error("Failed to fetch ironman.")); // Reject if not successful
        }
      } catch (error) {
        reject(error); // Reject on any error during the request
      }
    });
  };

  const changeIron = (evt) => {
    if (evt.target.value != "") {
      const actualService = ironMan.find(
        (s) => s?.id === parseInt(evt.target.value)
      );
      setSelectedIron(actualService);
    }
  };

  const updateStatus = async () => {
    const form = new FormData();

    form.append("status", status);
    if (status == "Pending") {
      form.append("ironmanid", null);
      form.append("ironman", "");
    } else {
      form.append("ironmanid", selectedIron?.id);
      form.append("ironman", selectedIron?.ironmanName);
    }

    const paymentData = await axiosInstance.put(
      `/orders?id=${selectedOrder?.id}`,
      form
    );
    if (paymentData.status == "success") {
      getOrders();
      setSelectIron(false);
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Order Status " + paymentData.message,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Order Status " + paymentData.message,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
    }
  };

  useEffect(() => {
    getOrders();
    getIronman();
    const drake = dragula([
      pendingRef.current,
      processingRef.current,
      readyRef.current,
    ]);

    drake.on("drop", async (el, target, source, sibling) => {
      const draggedId = parseInt(el.id);

      const draggedItem =
        pendingOrderRef.current.find((item) => item.id === draggedId) ||
        processingOrderRef.current.find((item) => item.id === draggedId) ||
        readyOrderRef.current.find((item) => item.id === draggedId);

      if (draggedItem) {
        console.log(
          `Moved ${draggedItem.customername} (#${draggedItem.orderid}) from ${source.id} to ${target.id}`
        );

        setSelectedOrder(draggedItem);
        let sts = "";
        switch (target.id) {
          case "pending":
            setStatus("Pending");
            sts = "Pending";
            break;
          case "processing":
            setStatus("Processing");
            sts = "Processing";

            break;
          case "ready":
            setStatus("Ready to Deliver");
            sts = "Ready to Deliver";
            break;
          default:
            break;
        }

        if (target.id === "processing") {
          drake.cancel(true);
          setSelectIron(true);
        } else if (draggedItem?.status === "Pending" && target.id === "ready") {
          drake.cancel(true);
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "You can change 'Pending' to 'Processing'.",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            customClass: {
              popup: "custom-toast",
            },
          });
        } else {
          const form = new FormData();
          form.append("status", sts);
          if (sts == "Pending") {
            form.append("ironmanid", null);
            form.append("ironman", "");
          }
          const paymentData = await axiosInstance.put(
            `/orders?id=${draggedItem?.id}`,
            form
          );
          if (paymentData.status == "success") {
            // getOrders();
            setSelectIron(false);
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Order Status " + paymentData.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
          } else {
            drake.cancel(true);
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "Order Status " + paymentData.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
          }
        }

        console.log("Full object:", draggedItem);
      } else {
        drake.cancel(true);
        console.error("Item not found!");
      }
    });

    return () => {
      drake.destroy();
    };
  }, []);

  useEffect(() => {
    pendingOrderRef.current = pendingOrder;
  }, [pendingOrder]);

  useEffect(() => {
    processingOrderRef.current = processingOrder;
  }, [processingOrder]);

  useEffect(() => {
    readyOrderRef.current = readyOrder;
  }, [readyOrder]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">
                Order Status
              </h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                onClick={() => {
                  navigate("/createorder");
                }}
                className="align-top btn btn-sm btn-info"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <section className="content card mx-3 shadow">
        <div className="container-fluid card-body">
          <div className="row">
            {/* Pending Column */}
            <div className="col-md-4">
              <div className="card card-secondary shadow">
                <div className="card-header">
                  <h3 className="card-title">Pending</h3>
                </div>
                <div
                  className="card-body"
                  style={{ minHeight: "320px" }}
                  id="pending"
                  ref={pendingRef}
                >
                  {pendingOrder?.map((item, index) => {
                    return (
                      <div
                        className="info-box bg-gradient-light shadow border"
                        style={{ cursor: "all-scroll" }}
                        id={item.id}
                        key={item.id}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className="pr-2">{item.customername}</div>
                          <div className="pl-2">#{item.orderid}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Processing Column */}
            <div className="col-md-4">
              <div className="card card-warning shadow">
                <div className="card-header">
                  <h3 className="card-title">Processing</h3>
                </div>
                <div
                  className="card-body"
                  style={{ minHeight: "320px" }}
                  id="processing"
                  ref={processingRef}
                >
                  {processingOrder?.map((item, index) => {
                    return (
                      <div
                        className="info-box bg-gradient-light shadow border"
                        style={{ cursor: "all-scroll" }}
                        id={item.id}
                        key={item.id}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className="pr-2">{item.customername}</div>
                          <div className="pl-2">#{item.orderid}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Ready to Deliver Column */}
            <div className="col-md-4">
              <div className="card card-primary shadow">
                <div className="card-header">
                  <h3 className="card-title">Ready to Deliver</h3>
                </div>
                <div
                  className="card-body"
                  style={{ minHeight: "320px" }}
                  id="ready"
                  ref={readyRef}
                >
                  {readyOrder?.map((item, index) => {
                    return (
                      <div
                        className="info-box bg-gradient-light shadow border"
                        style={{ cursor: "all-scroll" }}
                        id={item.id}
                        key={item.id}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className="pr-2">{item.customername}</div>
                          <div className="pl-2">#{item.orderid}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`modal fade ${selectIron ? "show" : ""}`}
        style={{ display: selectIron ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden={!selectIron}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCustomerModalLabel">
                Select Iron Man
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  setSelectIron(false);
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" id="addCustomer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info shadow">
                      <div className="card-body">
                        <div>
                          <select
                            name="status"
                            className="form-control change-status"
                            value={selectedIron?.id}
                            onChange={changeIron}
                          >
                            <option value="">Select Iron Man</option>
                            {ironMan.map((item, index) => (
                              <option value={item?.id}>
                                {item.ironmanName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={updateStatus}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderStatus;
