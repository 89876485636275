import "./App.css";
import Dashboard from "./Dashboard/Dashboard";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Customer from "./Customer/Customer";
import { useSelector } from "react-redux";
import ServiceType from "./Servicetype/Servicetype";
import Service from "./Service/Service";
import CreateService from "./Service/CreateService";
import Createorder from "./Order/Createorder";
import OrderList from "./Order/OrderList";
import Orderview from "./Order/Orderview";
import Ironman from "./Ironman/Ironman";
import OrderStatus from "./Order/OrderStatus";
import IronmanView from "./Ironman/IronmanView";
import Washing from "./Washing/Washing";
import WashingView from "./Washing/WashingView";

const ProtectedRoute = ({ children, isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  return (
    <div className="App">
      <div id="load">
        <div className="loader">spinner</div>
      </div>

      <div className="wrapper position-relative">
        {isLoggedIn && (
          <>
            <Header />
            <SideMenu />
          </>
        )}

        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={isLoggedIn ? "/dashboard" : "/login"} replace />
            }
          />
          <Route path="login" element={<Login />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="customer"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Customer />
              </ProtectedRoute>
            }
          />
          <Route
            path="servicetype"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <ServiceType />
              </ProtectedRoute>
            }
          />
          <Route
            path="services"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Service />
              </ProtectedRoute>
            }
          />
          <Route
            path="createservice"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <CreateService />
              </ProtectedRoute>
            }
          />
          <Route
            path="createorder"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Createorder />
              </ProtectedRoute>
            }
          />
          <Route
            path="orderlist"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <OrderList />
              </ProtectedRoute>
            }
          />
          <Route
            path="orderview"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Orderview />
              </ProtectedRoute>
            }
          />
          <Route
            path="ironman"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Ironman />
              </ProtectedRoute>
            }
          />
          <Route
            path="orderstatus"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <OrderStatus />
              </ProtectedRoute>
            }
          />
          <Route
            path="ironmanview"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <IronmanView />
              </ProtectedRoute>
            }
          />
          <Route
            path="washing"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Washing />
              </ProtectedRoute>
            }
          />
          <Route
            path="washingview"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <WashingView />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <Navigate to={isLoggedIn ? "/dashboard" : "/login"} replace />
            }
          />
        </Routes>

        <footer className="main-footer bg-info text-white">
          <strong>Copyright © 2024 Shanzio Info Tech</strong>
        </footer>
      </div>
    </div>
  );
}

export default App;
