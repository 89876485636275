import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import Print from "../Print";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import { useNavigate } from "react-router-dom";

const OrderList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [printMode, setPrintMode] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    const initializeDataTable = () => {
      if ($.fn.DataTable.isDataTable("#customer-list")) {
        $("#customer-list").DataTable().clear().destroy();
      }

      // Initialize DataTable
      $("#customer-list").DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: async (data, callback) => {
          try {
            // Calculate the current page and limit
            const page = Math.floor(data.start / data.length) + 1; // Convert to page number (1-based)
            const limit = data.length; // Number of records to fetch
            // const searchTerm = data.search.value; // Search term if any
            // Extract search value from DataTables
            const searchTerm = data.search.value || "";

            // Build the search query across multiple columns
            const searchParams = new URLSearchParams();
            if (searchTerm != "") {
              [
                "orderid",
                "customername",
                "orderdate",
                "deliveryDate",
                "netAmt",
                "status",
              ].forEach((col) => {
                searchParams.append(col, `%${searchTerm}%`);
              });
              searchParams.append("conditionType", "OR");
            }
            searchParams.append("limit", limit);
            searchParams.append("page", page);

            // Handle ordering dynamically
            if (data.order.length == 0) {
              searchParams.append(
                "orderBy",
                JSON.stringify([{ column: "createdon", direction: "desc" }])
              );
            }
            if (data.order[0]?.name == "sno") {
              searchParams.append(
                "orderBy",
                JSON.stringify([{ column: "createdon", direction: "asc" }])
              );
            } else {
              const orderDirection =
                data.order[0]?.name != "" ? data.order[0]?.dir : "desc";
              const orderByColumn =
                data.order[0]?.name != "" ? data.order[0]?.name : "createdon";

              searchParams.append(
                "orderBy",
                JSON.stringify([
                  { column: orderByColumn, direction: orderDirection },
                ])
              );
            }

            // Make the API call
            const response = await axiosInstance.get(`/orders?${searchParams}`);

            // Ensure the response format matches what DataTables expects
            callback({
              draw: data.draw, // Pass the draw parameter back
              recordsTotal: response.totalRecordsBeforeFilter, // Total records before filtering
              recordsFiltered: response.totalRecordsAfterFilter, // Total records after filtering
              data: response.data, // The actual data array
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error (optional)
          }
        },
        columns: [
          {
            data: null,
            name: "sno",
            render: (data, type, row, meta) => {
              // Calculate the correct S. No. based on the page
              const pageInfo = $("#customer-list").DataTable().page.info();
              return meta.row + 1 + pageInfo.start;
            },
          }, // S NO.
          {
            data: null,
            name: "id",
            render: (data, type, row, meta) => {
              return `<div><div>Order ID: <b>${
                row.orderid
              }</b></div><div>Order Date: <b>${new Date(
                row.orderdate
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}</b></div><div>Delivery Date: <b>${new Date(
                row.deliveryDate
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}</b></div></div>`;
            },
          },
          {
            data: "customername",
            name: "customername",
          },

          {
            data: null,
            name: "netAmt",
            render: (data, type, row, meta) => {
              return `<div style="text-align:left"> <i class="fas fa-rupee-sign"></i><b>${row.netAmt}</b></div>`;
            },
          },

          {
            data: null,
            name: "paidAmt",
            render: (data, type, row, meta) => {
              return `<div><div>Total Amount: <i class="fas fa-rupee-sign"></i><b>${
                row.netAmt
              }</b></div><div>Paid Amount: <i class="fas fa-rupee-sign"></i> <b> ${
                row.paidAmt
              }</b></div><div>Balance Amount: <i class="fas fa-rupee-sign"></i><b> ${
                parseInt(row.netAmt) - parseInt(row.paidAmt)
              }</b></div></div>`;
            },
          },

          {
            data: null,
            name: "status",
            render: (data, type, row, meta) => {
              var badge = "";
              switch (row.status) {
                case "Pending":
                  badge = '<span class="badge btn-secondary">Pending</span>';
                  break;
                case "Ready to Deliver":
                  badge =
                    '<span class="badge btn-primary">Ready to Deliver</span>';
                  break;
                case "Processing":
                  badge = '<span class="badge btn-warning">Processing</span>';
                  break;
                case "Delivered":
                  badge = '<span class="badge btn-success">Delivered</span>';
                  break;
                case "Returned":
                  badge = '<span class="badge btn-danger">Returned</span>';
                  break;
                default:
                  badge = '<span class="badge btn-secondary">Pending</span>';
                  break;
              }

              return badge;
            },
          },
          {
            data: null,
            render: () => `
                        <button class="btn btn-dark btn-sm view-btn">View</button>
                        <button class="btn btn-danger btn-sm print-btn">Print</button>
                    `,
          },
        ],
        language: {
          lengthMenu: "Show _MENU_ entries",
          search: "Search:",
          paginate: {
            next: "Next",
            previous: "Previous",
          },
          info: "Showing _START_ to _END_ of _TOTAL_ entries",
        },
      });

      $("#customer-list tbody").on("click", ".print-btn", function () {
        const rowData = $("#customer-list")
          .DataTable()
          .row($(this).parents("tr"))
          .data();

        setOrder(rowData);
        setPrintMode(true);

        setTimeout(() => {
          window.print();
          setPrintMode(false);
          window.location.reload();
        }, 100);
      });
      $("#customer-list tbody").on("click", ".view-btn", function () {
        const rowData = $("#customer-list")
          .DataTable()
          .row($(this).parents("tr"))
          .data();

        console.log(rowData);
        $("#load").show();

        navigate("/orderview", {
          state: { data: rowData.orderid },
        });
      });

      $("#load").hide();
    };

    // Call the DataTable initialization function
    initializeDataTable();

    // Cleanup on component unmount
    return () => {
      if ($.fn.DataTable.isDataTable("#customer-list")) {
        $("#customer-list").DataTable().clear().destroy();
      }
    };
  }, []);

  return !printMode ? (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Orders</h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                onClick={() => {
                  navigate("/createorder");
                }}
                className="align-top btn btn-sm btn-info"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table
            id="customer-list"
            style={{ width: "100%" }}
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Order Amount</th>
                <th>Payment</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <Print order={order} />
  );
};
export default OrderList;
