import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import Print from "../Print";
import $, { event } from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import { useNavigate, useLocation } from "react-router-dom";

const IronmanView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let id = location.state.data;
  const [paymentModal, setPaymentModal] = useState(false);
  const [payment, setPayment] = useState([]);
  const [bal, setBal] = useState(0);
  const [ironman, setIronman] = useState([]);
  const [ironmanprice, setIronmanprice] = useState([]);
  const [totalAmt, settotalAmt] = useState(0);
  const [paidAmt, setpaidAmt] = useState(0);

  const getIronman = async () => {
    const response = await axiosInstance.post("/sqlquery", {
      query: `SELECT 
                O.orderid,
                SUM(OI.qty) AS qty,  -- Sum of quantities for the same orderid
                GROUP_CONCAT(CONCAT(OI.qty, ' - ', OI.serviceName) ORDER BY OI.serviceName SEPARATOR ', ') AS serviceName,  
                GROUP_CONCAT(CONCAT(IP.price, ' * ', OI.qty  ) ORDER BY OI.serviceName SEPARATOR ', ') AS Bprice,  SUM(IP.price * OI.qty) AS TotalEarn,  
                COUNT(DISTINCT O.orderid) AS TotalOrder,
                I.*
            FROM 
                orders O
            JOIN 
                orderitems OI ON OI.orderid = O.id AND OI.servicetypeName = 'iron'
            JOIN 
                ironmanprice IP ON IP.ironmanid = O.ironmanid AND IP.servicename = OI.serviceName
            JOIN 
                ironmans I ON I.id = O.ironmanid 
            WHERE 
                O.ironmanid = ${id}
                AND O.status IN ('Ready to Deliver', 'Delivered')
            GROUP BY 
                O.orderid
`,
    });

    if (response?.status == "success") {
      setIronman(response.data);
      settotalAmt(
        response.data.reduce((accum, item) => accum + item.TotalEarn, 0)
      );
    }
    const response2 = await axiosInstance.get("/ironmanprice?ironmanid=" + id);

    if (response2?.status == "success") {
      setIronmanprice(response2.data);
    }
    const response3 = await axiosInstance.get(
      "/ironmanpayment?ironmanid=" + id
    );

    if (response3?.status == "success") {
      setPayment(response3.data);
      setpaidAmt(
        response3.data.reduce((accum, item) => accum + item.paidAmt, 0)
      );
    }
    $("#load").hide();
  };

  const addPayment = async (event) => {
    event.preventDefault();
    const baaa = totalAmt - paidAmt;
    const pt = $("#paymentType").val();
    if (bal > 0 && bal <= baaa) {
      if (pt === "" || pt === null) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Select Payent method",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
        return;
      }

      var orderpayment = {
        ironmanid: id,
        paidAmt: bal,
        paymentType: pt,
      };
      const paymentData = await axiosInstance.post(
        `/ironmanpayment`,
        orderpayment
      );

      if (paymentData.status == "success") {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Order payment " + paymentData.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
        setPaymentModal(false);
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Order payment " + paymentData.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
      }
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Paid Amount should be greater than 0 less than " + baaa,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
      return;
    }
  };

  useEffect(() => {
    getIronman();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">
                Ironman Payments
              </h1>
            </div>

            <div className="col-sm-6 text-right align-self-center"></div>
          </div>
        </div>
      </div>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="text-uppercase font-weight-bold">
                        {ironman[0]?.ironmanName}
                      </h5>
                      <div>
                        <p className="m-0">{ironman[0]?.phone}</p>
                        <p className="m-0">{ironman[0]?.email}</p>
                        <p className="m-0">{ironman[0]?.address}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    {ironmanprice.map((item, index) => (
                      <div className="col-md-3">
                        <p>
                          <span className="text-capitalize">
                            {item.servicename}:{" "}
                          </span>
                          <span className="font-weight-bold ms-2">
                            <i className="fas fa-rupee-sign"></i>&nbsp;
                            {item.price}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="bg-light">
                        <tr>
                          <th>#</th>
                          <th>Service Name</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ironman.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.orderid}</td>
                              <td>{item.serviceName}</td>
                              <td>{item.Bprice}</td>
                              <td>{item.TotalEarn}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <h5 className="font-weight-bold">Payment Details</h5>
                      <div className="row">
                        <div className="col"> Total Earn:</div>
                        <div className="col-auto">
                          {" "}
                          <i className="fas fa-rupee-sign"></i>
                          {totalAmt}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">Paid Amount:</div>
                        <div className="col-auto">
                          {" "}
                          - <i className="fas fa-rupee-sign"></i> {paidAmt}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Balance:</b>
                        </div>
                        <div className="col-auto">
                          {" "}
                          <i className="fas fa-rupee-sign"></i>
                          {totalAmt - paidAmt}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <h5 className="font-weight-bold mb-3">Payments</h5>
                    <ul className="list-group">
                      {payment?.map((item) => (
                        <li className="list-group-item">
                          <b>
                            <i className="fas fa-rupee-sign"></i> {item.paidAmt}{" "}
                          </b>
                          <p className="text-muted">
                            {new Date(item?.createdon).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )}
                            [{item.paymentType}]
                          </p>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="btn btn-sm btn-block btn-info mb-2"
                      onClick={() => {
                        setPaymentModal(true);
                      }}
                    >
                      Add Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`modal fade ${paymentModal ? "show" : ""}`}
        style={{ display: paymentModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden={!paymentModal}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Payment </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close-payment"
                onClick={() => {
                  setPaymentModal(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row border-bottom pb-3 mb-3">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">Paid Amount</label>
                    <input
                      type="number"
                      name="amount"
                      onChange={(e) => {
                        setBal(parseInt(e.target.value));
                      }}
                      value={bal}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">Payment Type</label>
                    <select
                      name="method"
                      id="paymentType"
                      className="form-control"
                    >
                      <option disabled="disabled" selected="selected" value="">
                        Select The Payment Method
                      </option>
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="cheque">cheque</option>
                      <option value="bank_transfer">Bank Transfer</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-end">
              <button
                type="button"
                className="btn btn-info text-right"
                onClick={addPayment}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IronmanView;
