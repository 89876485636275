import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import $ from "jquery";
import { useNavigate, Link } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

const Dashboard = () => {
  const [count, setCount] = useState({});
  const [deliveryOrder, setDeliveryOrder] = useState([]);
  const navigate = useNavigate();
  Chart.register(ArcElement, Tooltip, Legend);
  const [data, setData] = useState({
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        data: [30, 50, 20],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
    },
  };

  const getTodayOds = async () => {
    const searchParams = new URLSearchParams();
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    searchParams.append("deliveryDate", formattedDate);
    const response = await axiosInstance.get(`/orders?${searchParams}`);
    setDeliveryOrder(response.data);
  };

  const getAllCounts = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("columns", "Count(*) AS count");
    searchParams.append("status", "Pending");
    const response = await axiosInstance.get(`/orders?${searchParams}`);
    searchParams.set("status", "Processing");
    const response2 = await axiosInstance.get(`/orders?${searchParams}`);
    searchParams.set("status", "Ready to Deliver");
    const response3 = await axiosInstance.get(`/orders?${searchParams}`);
    searchParams.set("status", "Delivered");
    const response4 = await axiosInstance.get(`/orders?${searchParams}`);
    searchParams.set("status", "Returned");
    const response5 = await axiosInstance.get(`/orders?${searchParams}`);

    setCount({
      pending: response.data[0].count,
      processing: response2.data[0].count,
      ready: response3.data[0].count,
      deliver: response4.data[0].count,
    });

    setData({
      labels: [
        "Pending",
        "Processing",
        "Ready to Deliver",
        "Delivered",
        "Returned",
      ],
      datasets: [
        {
          data: [
            response.data[0].count,
            response2.data[0].count,
            response3.data[0].count,
            response4.data[0].count,
            response5.data[0].count,
          ],
          backgroundColor: [
            "#6c757d",
            "#ffc107",
            "#007bff",
            "#28a745",
            "#dc3545",
          ],
          borderColor: ["#6c757d", "#ffc107", "#007bff", "#28a745", "#dc3545"],
          borderWidth: 1,
        },
      ],
    });

    $("#load").hide();
  };

  const getsts = (status) => {
    var badge = "";
    switch (status) {
      case "Pending":
        badge = '<span class="badge btn-secondary">Pending</span>';
        break;
      case "Ready to Deliver":
        badge = '<span class="badge btn-primary">Ready to Deliver</span>';
        break;
      case "Processing":
        badge = '<span class="badge btn-warning">Processing</span>';
        break;
      case "Delivered":
        badge = '<span class="badge btn-success">Delivered</span>';
        break;
      case "Returned":
        badge = '<span class="badge btn-danger">Returned</span>';
        break;
      default:
        badge = '<span class="badge btn-secondary">Pending</span>';
        break;
    }

    return badge;
  };

  useEffect(() => {
    getAllCounts();
    getTodayOds();
  }, []);
  useEffect(() => {
    console.log(count);
  }, [count]);
  return (
    <div className="content-wrapper">
      {/* Content Header */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Info boxes */}
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>{count?.pending}</h3>
                  <p>Pending Orders</p>
                </div>
                <div className="icon">
                  <i className="fa fa-shopping-bag"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>{count?.processing}</h3>
                  <p>Processing Order</p>
                </div>
                <div className="icon">
                  <i className="ion fa ion-stats-bars"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>{count?.ready}</h3>
                  <p>Ready to Deliver</p>
                </div>
                <div className="icon">
                  <i className="fa fa-thumbs-up"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>{count?.deliver}</h3>
                  <p>Delivered Orders</p>
                </div>
                <div className="icon">
                  <i className="fa fa-check"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="card card-info shadow mx-2">
                <div className="card-header">
                  <h3 className="card-title">Today's Delivery</h3>
                </div>
                <div className="card-body" style={{ minHeight: "280px" }}>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer</th>
                          <th>Order Date</th>
                          <th>Order Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryOrder?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.customername}</td>
                              <td>
                                {new Date(item?.createdon).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                  }
                                )}
                              </td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: getsts(item.status),
                                }}
                              ></td>
                              <td>
                                <a
                                  onClick={() => {
                                    $("#load").show();
                                    navigate("/orderview", {
                                      state: { data: item.orderid },
                                    });
                                  }}
                                  className="btn btn-info btn-sm"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <Link
                    to={{ pathname: "/orderlist" }}
                    className="btn btn-sm btn-info float-right"
                  >
                    View All Orders
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-info shadow mx-2">
                <div className="card-header">
                  <h3 className="card-title">Orderview</h3>
                </div>
                <div className="card-body" style={{ minHeight: "280px" }}>
                  <Pie data={data} options={options} />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
