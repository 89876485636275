import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import { useNavigate } from "react-router-dom";
const Washing = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    washingName: "",
    phone: "",
    email: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [customers, setCustomers] = useState([]);
  const [service, setService] = useState([]);
  const [servicePrices, setServicePrices] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/washing");
      if (response?.status === "success") {
        setCustomers(response.data);
      } else {
        console.error("Error fetching washing:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching washing:", error);
    }
  };
  const handleModalOpen = async (customer) => {
    setShowModal(true);
    console.log(customer);

    if (customer == undefined || customer == null) {
      setFormData({ washingName: "", phone: "", email: "", address: "" });
      setServicePrices([]);

      setErrors({});
    } else {
      setFormData(customer);
      const response = await axiosInstance.get(
        "/washingprice?washingid=" + customer.id
      );
      if (response?.status === "success") {
        setServicePrices(response.data);
      } else {
        console.error("Error fetching washing:", response);
        setServicePrices([]);
      }

      setErrors({});
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    // Required fields
    if (!formData.washingName)
      newErrors.washingName = "washing name is required.";
    if (!formData.phone) newErrors.phone = "Phone number name is required.";

    const phoneRegex = /^\d{10}$/; // Matches 10 digits (without any formatting)
    if (formData.phone && !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits and Number.";
    }

    if (servicePrices.length <= 0) {
      newErrors.servicePrice = "At least one service price must be provided.";
    } else {
      for (let i = 0; i < servicePrices.length; i++) {
        const item = servicePrices[i]; // Current service price item
        const actualService = service.find((s) => s.id === item.serviceid);

        if (!actualService) {
          newErrors.servicePrice = `Service with ID ${item.serviceid} is invalid.`;
        } else if (Number(item.price) > Number(actualService.price)) {
          newErrors.servicePrice = `Price for ${item.servicename} cannot exceed the actual price of ${actualService.price}.`;
          break; // Exit loop on first error
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.id) {
        const data = new FormData();
        data.append("washingName", formData.washingName);
        data.append("phone", formData.phone);
        data.append("email", formData.email);
        data.append("address", formData.address);

        try {
          // Call the API using POST method
          const response = await axiosInstance.put(
            "/washing?id=" + formData.id,
            data
          );

          // Check the response from the API
          if (response?.status === "success") {
            // Fetch updated customers after adding a new customer
            saveServicePrices(formData.id, formData.washingName);

            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Customer " + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const data = new FormData();
        data.append("washingName", formData.washingName);
        data.append("phone", formData.phone);
        data.append("email", formData.email);
        data.append("address", formData.address);
        data.append("uniqueFields", JSON.stringify(["phone"]));

        try {
          // Call the API using POST method
          const response = await axiosInstance.post("/washing", data);

          // Check the response from the API
          if (response?.status === "success") {
            saveServicePrices(response?.data?.id, formData.washingName);
            // Fetch updated customers after adding a new customer
            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Iron man " + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else if (response?.status === "duplicate") {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "Iron man " + response.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/washing?id=${id}`);
      if (response.status === "success") {
        // Fetch updated customers after deletion
        await axiosInstance.delete("/washingprice?washingid=" + id);
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "iron man  " + response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchCustomers();
      } else {
        console.error("Error deleting iron man:", response.data);
      }
    } catch (error) {
      console.error("Error deleting iron man :", error);
    }
  };

  const fetchSerivce = async () => {
    const response = await axiosInstance.get("/serviceprice?service=%wash%");
    if (response.status === "success") {
      setService(response.data);
    }
  };

  const handleServicePriceChange = (e, serviceid, servicename) => {
    const { value } = e.target;

    // Update or add the service price in the array
    setServicePrices((prev) => {
      const updatedPrices = [...prev]; // Clone the previous state

      // Check if the serviceId already exists in the state
      const index = updatedPrices.findIndex(
        (item) => item.serviceid === serviceid
      );

      if (index !== -1) {
        // If found, update the price
        updatedPrices[index] = { ...updatedPrices[index], price: value };
      } else {
        // If not found, add a new entry
        updatedPrices.push({ serviceid, price: value, servicename });
      }

      return updatedPrices;
    });
  };

  const saveServicePrices = async (washingId, washingName) => {
    try {
      const servicePromises = servicePrices.map(async (service) => {
        const { serviceid, price, servicename, id } = service; // Destructure the service object

        const formData2 = new FormData();
        formData2.append("serviceid", serviceid);
        formData2.append("servicename", servicename);
        formData2.append("price", price);
        formData2.append("washingid", washingId);
        formData2.append("washingName", washingName);
        // Send the API request for each service
        if (id) {
          await axiosInstance.put("/washingprice?id=" + id, formData2);
        } else {
          await axiosInstance.post("/washingprice", formData2);
        }
      });

      // Wait for all service price API calls to complete
      await Promise.all(servicePromises);
    } catch (error) {
      console.error("Error saving service prices:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchSerivce();
  }, []); // Empty dependency array to run once on mount

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#customer-list")) {
      $("#customer-list").DataTable().clear().destroy();
    }

    $("#customer-list").DataTable({
      responsive: true,
      data: customers,
      columns: [
        { data: null, render: (data, type, row, meta) => meta.row + 1 }, // S NO.
        {
          data: "washingName",
        },
        { data: "phone" },
        { data: "email" },

        {
          data: "address",
        },
        {
          data: null,
          render: (data) => `
              <button class="btn btn-dark btn-sm edit-btn">Edit</button>
             
              <button class="btn btn-info btn-sm view-btn">View</button>
            `,
        },
        // <button class="btn btn-danger btn-sm delete-btn">Delete</button>
      ],
      language: {
        lengthMenu: "Show _MENU_ entries",
        search: "Search:",
        paginate: {
          next: "Next",
          previous: "Previous",
        },
        info: "Showing _START_ to _END_ of _TOTAL_ entries",
      },
    });

    $("#customer-list tbody").on("click", ".edit-btn", function () {
      const rowData = $("#customer-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleModalOpen(rowData);
    });
    $("#customer-list tbody").on("click", ".view-btn", function () {
      const rowData = $("#customer-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      navigate("/washingview", {
        state: { data: rowData.id },
      });
    });

    $("#customer-list tbody").on("click", ".delete-btn", function () {
      const rowData = $("#customer-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleDelete(rowData.id);
    });
    $("#load").hide();
  }, [customers]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">washing</h1>
            </div>
            {/* <div className="col-sm-6 text-right align-self-center">
              <button
                onClick={() => {
                  handleModalOpen(null);
                }}
                className="align-top btn btn-sm btn-info"
              >
                Add New
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table
            id="customer-list"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Washing Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding new customer */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCustomerModalLabel">
                {formData.id ? "Edit Washing" : "Add Washing"}
              </h5>
              <button
                type="button"
                className="close"
                onClick={handleModalClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                id="addCustomer"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info shadow">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="form-group mb-4">
                              <label>
                                Washing Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="washingName"
                                value={formData.washingName}
                                onChange={handleChange}
                                placeholder="Enter User Name"
                              />
                              {errors.washingName && (
                                <small className="text-danger">
                                  {errors.washingName}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter Phone Number"
                              />
                              {errors.phone && (
                                <small className="text-danger">
                                  {errors.phone}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group mb-4">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter User Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-6">
                            <div className="form-group mb-4">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="Enter User Address"
                              />
                            </div>
                          </div>
                        </div>

                        <h3>Service Pricing</h3>
                        <div className="row">
                          {service.map((service) => (
                            <div className="col-md-6 col-6" key={service.id}>
                              <div className="form-group mb-4">
                                <label>{service.servicename}</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={
                                    servicePrices.find(
                                      (item) => item.serviceid === service.id
                                    )?.price || ""
                                  }
                                  onChange={(e) =>
                                    handleServicePriceChange(
                                      e,
                                      service.id,
                                      service.servicename
                                    )
                                  }
                                  placeholder={`Enter price for ${service.servicename}`}
                                />
                              </div>
                            </div>
                          ))}
                          {errors.servicePrice && (
                            <small className="text-danger">
                              {errors.servicePrice}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-info">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Washing;
